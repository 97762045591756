/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-9 10:24:58
 */
import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'
import { commonRequest } from './index'

export const loginRequest = (userInfo: RequestParams) => {
  return https(false).request<RootObject<LoginModel>>('auth', Method.POST, userInfo, ContentType.json)
}
// 获取机器人详情
export const getRobotDetail = (params: any) => {
	return commonRequest('robot-status/all', Method.GET, params, ContentType.form, true)
}
// 任务管理列表
export const taskList = (params: any, isError?: boolean) => {
	return commonRequest('robot-status/task_info', Method.GET, params, ContentType.form, isError)
}
// 任务详情
export const taskInfo = (params: any) => {
	return commonRequest('robot-status/task_detail', Method.GET, params, ContentType.form)
}
// 地图
export const mapInfo = (params: any) => {
	return commonRequest('robot-status/task_map', Method.GET, params, ContentType.form)
}
// 获取机器人自检信息
export const robotDni = (params: any) => {
	return https().request('robot-status/dni', Method.GET, params, ContentType.form)
}
// 获取日志
export const getLog = (params: any) => {
	return commonRequest('robot-log/log', Method.GET, params, ContentType.form)
}
// 累计消杀次数
export const disinfectCount = (params: any) => {
	return commonRequest('tasks/disinfect/count', Method.GET, params, ContentType.form)
}
// 累计消杀时长
export const disinfectDuration = (params: any) => {
	return commonRequest('tasks/disinfect/duration', Method.GET, params, ContentType.form)
}
// 区域任务次数&消杀时长
export const areaTaskDisinfect = (params: any) => {
	return commonRequest('tasks/disinfect/area-statistics', Method.GET, params, ContentType.form)
}
// 区域下每个房间的任务次数&消杀时长
export const roomTaskDisinfect = (params: any) => {
	return commonRequest('tasks/disinfect/room-statistics', Method.GET, params, ContentType.form)
}
// 机器人消杀频率
export const robotDisinfect = (params: any) => {
	return commonRequest('tasks/disinfect/robot-statistics', Method.GET, params, ContentType.form)
}

// 重置机器人登录密码
export const resetLogin = (params: any) => {
	return commonRequest(`robot-setting/app_login?sn=${params.sn}`, Method.POST, params.data, ContentType.json)
  }
// 最新的日志下载信息
export const logData = (params: any) => {
	return commonRequest('robot-log/last', Method.GET, params, ContentType.form)
}
// 验证token
export const checkToken = (params: any) => {
return commonRequest('alive', Method.GET, params, ContentType.form)
}
// 删除机器人
export const delRobot = (params: any) => {
	return commonRequest(`robots/${params.sn}`, Method.DELETE, undefined, ContentType.form)
}
// 更新机器人
export const changeRobot = (params: any) => {
	return commonRequest(`robots/${params.sn}`, Method.PUT, params.body, ContentType.json)
}
// 导出消杀列表
export const exportTask = (params: any) => {
	return commonRequest('tasks/export', Method.POST, params, ContentType.json)
}
// 取消获取日志
export const cancelLogReq = (params: any) => {
	return commonRequest(`robot-log/cancel`, Method.POST, params, ContentType.form)
}