<template>
  <div class="taskInfo">
    <el-breadcrumb separator="/" class="breadcrumb">
      <!-- <el-breadcrumb-item :to="{path: '/dashboard'}">
        机器人
      </el-breadcrumb-item> -->
      <el-breadcrumb-item class="needGo" @click="goDisinfect">
        {{ $t('route.disinfect') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('disinfect.xsrwxq') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="taskDetail">
      <!-- <p class="title"><img :src="totalImg.back" alt="" @click="goBack"> <span>消杀任务详情</span></p> -->
      <!-- <p class="title">
        <img alt="" @click="goBack"> <span>{{$t('disinfect.xsrwxq')}}</span>
      </p> -->
      <div v-show="TaskCurrentStatus === 'A' || TaskCurrentStatus === 'B'" class="xsstatus">
        <el-button v-show="TaskCurrentStatus === 'B'" @click="goOnTask" @keyup.space.prevent.native
          @keydown.enter.prevent.native>
          {{ $t('disinfect.jxrw') }}
        </el-button>
        <el-button v-show="TaskCurrentStatus === 'A'" @click="pasuetask" @keyup.space.prevent.native
          @keydown.enter.prevent.native>
          {{ $t('disinfect.ztrw') }}
        </el-button>
        <el-button @click="stoptaskfalge = true" @keyup.space.prevent.native @keydown.enter.prevent.native>
          {{ $t('disinfect.jsrw') }}
        </el-button>
      </div>
      <el-row class="contentBox">
        <el-col :sm="8" :lg="8" class="infoItem">
          <p>
            <span>{{ $t('route.robot') }}</span>
            <span class="ssidname" @click="jumpTo">
              {{ robotssidname.nickname ?? robotssidname.ssid }}
            </span>
          </p>
          <p>
            <span>{{ $t('disinfect.room') }}</span>
            <span>{{ taskmessge.room_name ? taskmessge.room_name : "" }}</span>
          </p>
          <!-- <p>
            <span>{{$t('disinfect.pattern')}}</span>
            <span>{{
              taskmessge.task_type ? disinfectType(taskmessge.task_type) : disinfectType(taskmessge.type)
            }}</span>
          </p> -->
          <p>
            <span>{{ $t('user.operator') }}</span>
            <span>
              {{ taskmessge.operator }}
            </span>
          </p>
        </el-col>
        <el-col :sm="8" :lg="8" class="infoItem">
          <p>
            <span>{{ $t('disinfect.rwzsc') }}</span>
            <!-- <span>{{getTaskTime()}}</span> -->
            <span>{{ coundownTime ? coundownTime : "--" }}</span>
          </p>
          <p>
            <span>{{ $t('disinfect.totalTime') }}</span>
            <!-- <span>{{
							getHM(info.count_time)
							}}</span> -->
            <span>{{ sjxssjtime ? sjxssjtime : "--" }}</span>
          </p>
          <!-- <p>
            <span>{{$t('system.createTime')}}</span>
            <span>{{
              taskmessge.time_start_ts ? moment(taskmessge.time_start_ts*1000).format("YYYY-MM-DD HH:mm:ss") : moment(taskmessge.time_start*1000).format("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </p> -->
        </el-col>
        <el-col :sm="8" :lg="8" class="infoItem">
          <p>
            <span>{{ $t('disinfect.pattern') }}</span>
            <span>{{
              taskmessge.task_type ? disinfectType(taskmessge.task_type) : disinfectType(taskmessge.type)
            }}</span>
          </p>
          <p>
            <span>{{ $t('system.createTime') }}</span>
            <span>{{
              taskmessge.time_start_ts ? taskmessge.time_start_ts > 0 ?
              moment(taskmessge.time_start_ts * 1000).format(momentStamp('datetime')) : '--' :
              taskmessge.time_start > 0 ? moment(taskmessge.time_start * 1000).format(momentStamp('datetime')) : '--'
            }}</span>
          </p>
        </el-col>
      </el-row>
      <div class="contentsuoyou">
        <div class="contentmap mapBox">
          <!-- <span v-for="(item, index) in mapUrl" :key= index>
					<img :src="item" alt="">
				</span>	 -->
          <!-- <div class="borderclass">
            {{$t('disinfect.xsditu')}}
          </div> -->
          <!-- <img
            :src="mapUrl ? mapUrl : totalImg.noMap"
            alt=""
          > -->
          <img v-show="(taskmessge.type == 'disinfect' || taskmessge.task_type == 'disinfect')"
            src="@/assets/images/map/disfinectType.png" class="disfinectType" alt="">
          <div id="canvasBox" v-show="mapUrl && (taskmessge.type != 'disinfect' && taskmessge.task_type != 'disinfect')">
            <div class="zoom">
              <div class="add" @click="addScale"></div>
              <div class="reduce" @click="minScale"></div>
              <img src="@/assets/images/map/zoom.png" class="zoomImg" alt="">
            </div>
            <!-- <img src="@/assets/images/map/hotImg.png" class="hotImg" alt=""> -->
            <canvas id="map-canvas"></canvas>
          </div>
          <p class="noMap" v-show="!mapUrl && (taskmessge.type != 'disinfect' && taskmessge.task_type != 'disinfect')">
            <img src="@/assets/images/map/noMap.png" class="noMapImg" alt="">
            {{ $t('disinfect.gqyzwdt') }}
          </p>
        </div>
        <div class="contenttask">
          <!-- <el-timeline style="width: 300px; margin-left:24px">
            <el-timeline-item>
              {{ getCaption(taskmessge.time_start_ts?taskmessge.time_start_ts:taskmessge.time_start) }} :{{$t('disinfect.rwks')}}
            </el-timeline-item>
            <el-timeline-item v-for="(item, index) in listfather" :key="index">
              <span v-show="item.phase_type === 'pause'">
                {{ getCaption(item.time_start) }} :{{formstatus(item)}}
              </span>
              <span v-show="item.phase_type === 'dock'">
                {{ getCaption(item.time_start) }} :{{$t('robot.dock')}}
              </span>
              <span v-show="item.phase_type === 'leave_dock'">
                {{ getCaption(item.time_start) }} :{{$t('robot.leave_dock')}}
              </span>
              <span v-show="item.phase_type === 'explore'">
                {{ getCaption(item.time_start) }} :{{$t('disinfect.tansuo')}}
              </span>
              <span v-show="item.phase_type === 'location'">
                {{ getCaption(item.time_start) }} :{{$t('robot.location')}}
              </span>
              <span v-show="item.phase_type === 'navigation_origin'">
                {{ getCaption(item.time_start) }} :{{$t('robot.navigation_origin')}}
              </span>
              <span v-show="item.phase_type === 'navigation_dock'">
                {{ getCaption(item.time_start) }} :{{$t('disinfect.huidaocdz')}}
              </span>
              <span v-show="
                  (taskmessge.task_type?taskmessge.task_type === 'deploy_disinfect':taskmessge.type === 'deploy_disinfect') &&
                    item.phase_type === 'navigation'
                ">
                {{ getCaption(item.time_start) }} :{{
                  item.status != "failed" &&
                    item.status != "canceled" &&
                    item.status != "idle"
                    ? $t('robot.navigation')
                    : item.action === "disinfect"
                      ? $t('disinfect.wfddxsd') + (item.point_index + 1)
                      : $t('disinfect.wfdddhd') + (item.point_index + 1)
                }}
              </span>
              <span v-show="
                  (taskmessge.task_type?taskmessge.task_type != 'deploy_disinfect':taskmessge.type != 'deploy_disinfect') &&
                    item.phase_type === 'navigation'
                ">
                {{ getCaption(item.time_start) }} :{{$t('robot.navigation')}}
              </span>
              <span v-show="item.phase_type === 'countdown'">
                {{ getCaption(item.time_start) }} :{{$t('robot.countdown')}}
                <span v-show="index === listfather.length - 1">{{
                  countdowntimemsg ? countdowntimemsg : ""
                }}</span>
              </span>
              <span v-show="item.phase_type === 'charging'">
                {{ getCaption(item.time_start) }} :{{$t('disinfect.jqrzzcd')}}
              </span>
              <span v-show="item.phase_type === 'disinfect'">
                {{ getCaption(item.time_start) }} :{{$t('disinfect.xszy')}}{{
                  item.disinfectindexs + 1 > 0 ? item.disinfectindexs + 1 : 1
                }}
                {{ item.status !='start'?item.time_end?newdateshuju(item.time_start*1000,item.time_end*1000)+'S':'':'' }}
              </span>
              <span v-show="item.phase_type === 'sleep'">
                {{ getCaption(item.time_start) }} :{{$t('disinfect.dddhd')}}{{
                  item.disinfectindexs + 1 > 0 ? item.disinfectindexs + 1 : 1
                }}
              </span>
            </el-timeline-item>
            <el-timeline-item v-if="statuszzm === 'shibai'">
              {{ getCaption(wanchengtime) }} :{{ endCause }}
            </el-timeline-item>
            <el-timeline-item v-if="statuszzm === 'shibai'">
              {{ getCaption(wanchengtime) }} :{{$t('disinfect.rwjs')}}
            </el-timeline-item>
            <el-timeline-item v-if="statuszzm === 'chenggong'">
              {{ getCaption(wanchengtime) }} :{{$t('disinfect.rwwc')}}
            </el-timeline-item>
          </el-timeline> -->
          <!-- <div
            class="fatserson fatsersongreen"
            v-show="showsjxssj && statuszzm === 'chenggong'"
            :style="{'fontWeight':'600'}">
            <div class="fatserimagefather1"><image class="fatserimage1" src="/static/newtask/finishnew.png" /></div>
            <div class="textrights fatserwancheng">{{$t('taskSet.rwcg')}}</div><div class="fatserwancheng textleftswancheng">{{ getCaption(wanchengtime) }}</div>
            </div
          >
          <div
            class="fatserson fatsersongreen"
           v-show="showsjxssj && statuszzm === 'shibai'"
            :style="{'color':'#05B4BC','fontWeight':'600'}">
            <div class="fatserimagefather1"><image class="fatserimage1" src="/static/newtask/endnew.png" /></div>
           {{$t('newdemand.rwjs')}}<div class="fatserwancheng textleftswancheng">{{ getCaption(wanchengtime) }}</div>
            </div
          >
          <div
            class="fatserson fatsersongreen"
           v-show="showsjxssj && statuszzm === 'shibai'"
            :style="{'color':'#05B4BC'}"
            >
            <div class="fatserson1"><div class="fatserson1son fatserson1sonhuise"></div></div>
           {{endCause}}<div class="fatserwancheng textleftswancheng">{{ getCaption(wanchengtime) }}</div>
            </div
          > -->
          <div class="detilendpointbox">
            <div class="fatserson fatsersongreen" v-show="showsjxssj && statuszzm === 'chenggong'"
              :style="{ 'fontWeight': '600' }">
              <div class="fatserimagefather1"><img class="fatserimage1" src="@/assets/images/home/finishnew.png" /></div>
              <div class="textrights fatserwancheng newminbox">{{ $t("disinfect.rwwc") }}</div>
              <div class="fatserwancheng textleftswancheng">{{ getCaption(wanchengtime) }}</div>
            </div>
            <div class="fatserson fatsersongreen" v-show="showsjxssj && statuszzm === 'shibai'"
              :style="{ 'color': '#05B4BC', 'fontWeight': '600' }">
              <div class="fatserimagefather1"><img class="fatserimage1" src="@/assets/images/home/endnew.png" /></div>
              <div class="newminbox">{{ $t("disinfect.rwjs") }}</div>
              <div class="fatserwancheng textleftswancheng">{{ getCaption(wanchengtime) }}</div>
            </div>
            <div class="fatserson fatsersongreen" v-show="showsjxssj && statuszzm === 'shibai'"
              :style="{ 'color': '#05B4BC' }">
              <div class="fatserson1">
                <div class="fatserson1son fatserson1sonhuise"></div>
              </div>
              <div class="newminbox">{{ endCause }}</div>
              <div class="fatserwancheng textleftswancheng">{{ getCaption(wanchengtime) }}</div>
            </div>
            <div v-for="(ele, index) in listfather" :key="index">
              <div class="newdiv">
                <div v-show="ele.phase_type === 'pause'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ formstatus(ele) }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'dock'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t('robot.dock') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'leave_dock'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t('robot.leave_dock') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'explore'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t('disinfect.tansuo') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'location'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t('robot.location') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'navigation_origin'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t('robot.navigation_origin') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div
                  v-show="ele.phase_type === 'navigation_dock' && (ele.status === 'failed' || ele.status === 'canceled') && ele.detail != 'no navigation'"
                  class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ ele.detail ===
                    'anticollision' ? $t('disinfect.cpfzt') : $t('minbox.daohangshibai') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'navigation_dock' && ele.detail != 'no navigation'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t('disinfect.huidaocdz') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div
                  v-show="(taskmessge.task_type ? taskmessge.task_type === 'deploy_disinfect' : taskmessge.type === 'deploy_disinfect') && ele.phase_type === 'navigation' && (ele.detail != 'manual' && ele.detail != 'no navigation') && ele.status != 'canceled'"
                  class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">
                    {{ ele.status != 'failed' && ele.status != 'canceled' && ele.status != 'idle' ? $t("robot.navigation") : ele.action === 'disinfect' ? $t("disinfect.wfddxsd") +
                      (ele.point_index + 1) : $t("disinfect.wfdddhd") + (ele.point_index + 1) }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div
                  v-show="(taskmessge.task_type ? taskmessge.task_type != 'deploy_disinfect' : taskmessge.type != 'deploy_disinfect') && ele.phase_type === 'navigation' && ele.status != 'failed' && ele.status != 'canceled' && ele.status != 'idle'"
                  class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t("robot.navigation") }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'countdown'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t("robot.countdown") }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                  <div class="timeclass" v-show="index === 0">{{ countdowntimemsg ? countdowntimemsg : '' }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'charging'" class="fatserson">
                  <div class="fatserson1" :class="{ colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson1son"
                      :class="{ fatserson1sonhuise: index != 0 || statuszzm === 'shibai' || statuszzm === 'chenggong' }"></div>
                  </div>
                  <div class="newminbox">{{ $t("disinfect.jqrzzcd") }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                  <div class="timeclass" v-show="index === 0">{{ countdowntimemsg ? countdowntimemsg : '' }}</div>
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'disinfect'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson2"
                    :class="{ fatserson2none: !ele.showFlag, colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson2son" v-show="ele.showFlag === true">{{ ele.disinfectindexs +
                      1 > 0 ? ele.disinfectindexs + 1 : 1 }}</div>
                  </div>
                  <div class="newminbox" :style="{ fontWeight: '600' }">{{ $t('disinfect.xszy') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                  <div v-if="ele.status != 'start'" class="textlefts textright">
                    {{ ele.time_end ? newdateshuju(ele.time_start * 1000, ele.time_end * 1000) + 'S' : '' }}</div>
                  <!-- <image v-show="ele.showVideo" @click="pruopVideo(ele)" class="iconmap4" src="/static/newtask/shipinbofang.png"></image> -->
                </div>
              </div>
              <div class="newdiv">
                <div v-show="ele.phase_type === 'sleep'" class="fatserson">
                  <div class="colorblack" v-show="index === 0 && !showsjxssj"></div>
                  <div class="fatserson2"
                    :class="{ fatserson2none: !ele.showFlag, colorfathercolor: index === 0 && !showsjxssj }">
                    <div class="fatserson2son fatserson2sonhuise" v-show="ele.showFlag === true">{{ ele.disinfectindexs +
                      1 > 0 ? ele.disinfectindexs + 1 : 1 }}</div>
                  </div>
                  <div class="newminbox">{{ $t('disinfect.dddhd') }}</div>
                  <div class="textlefts">{{ getCaption(ele.time_start) }}</div>
                  <div v-if="ele.status != 'start'" class="textlefts textright">
                    {{ ele.time_end ? newdateshuju(ele.time_start * 1000, ele.time_end * 1000) + 'S' : '' }}</div>
                </div>
              </div>
            </div>
            <div class="fatserson">
              <div class="fatsersonrwks"></div>
              <div class="fatserson1">
                <div class="fatserson1son fatserson1sonhuise"></div>
              </div>
              <div class="newminbox" :style="{ fontWeight: '600' }">{{ $t('disinfect.rwks') }}</div>
              <div class="textlefts">{{
                getCaption(taskmessge.time_start_ts ? taskmessge.time_start_ts : taskmessge.time_start) }}</div>
            </div>
          </div>
          <!-- <div class="fatserson"><div class="fatserson1"><div class="fatserson1son fatserson1sonhuise"></div></div>{{$t('taskSet.rwks')}}<div class="textlefts">{{ getCaption(taskmessge.time_start) }}</div></div> -->
        </div>
      </div>
    </div>
    <el-dialog v-model="stoptaskfalge" :close-on-press-escape="false" :close-on-click-modal="false"
      :title="$t('disinfect.jsrw')" width="30%" center :show-close="false">
      <div class="algincenter">{{ $t('disinfect.jjszzjxrw') }}</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="stoptaskfalge = false" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
            $t("system.cancel")
          }}</el-button>
          <el-button type="primary" @click="stoptask" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
            $t('disinfect.qdjsrw') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import {
  reactive,
  ref,
  toRefs,
  defineComponent,
  onMounted,
  watch,
  onBeforeUnmount,
} from "vue";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  debounce,
  throttle
} from "lodash";
import {
  fabric
} from "fabric";
import moment from 'moment'
import {
  regionList,
  delRegion,
  editRegion,
  createRegion,
  regionInfo,
  createTasks,
  Operationtasks,
  currenttask,
  taskInfo,
  taskstatus,
  reportList,
  nowMapList,
  robotssids,
  getmaps,
  getpos
} from "@/apis/region";
import internal from "stream";
import {
  ElMessage
} from "element-plus";
import i18n from "@/locales";
import {
  useStore
} from "@/store";
import {
  getHM,
  totalImg,
  disinfectType,
  getStep,
  momentStamp,
} from "@/utils/robot";
import {
  mapInfo
} from '@/apis/robot'
import { nextTick } from "process";
import { number } from "echarts";

interface reportface {
  TaskCurrentStatus: String
  taskmessge: any
  statustszxzz: string
  sn: string
  otalImg: any
  sjxssjtime: any
  coundownTime: any
  websocketUrl: any
  Noteven: Boolean
  listfather: any
  statuszzm: any
  countdowntimemsg: any
  wanchengtime: any
  endCause: any
  mapUrl: any;
  robotssidname: string,
  stoptaskfalge: boolean,
  showsjxssj: boolean,
  wallListObj: any,
  director: any;
  setdirectortimer: any
}
// interface resQusts{
//   duration:Number
// }
let websocket: any = null;
export default defineComponent({
  setup() {
    const router = useRouter();
    const route: any = useRoute();
    const details: reportface = reactive({
      TaskCurrentStatus: "", // A代表任务终止 D代表完成，B代表进行中,C代表暂停
      taskmessge: "", // 任务类型，
      statustszxzz: "",
      otalImg: totalImg(),
      momentStamp: momentStamp,
      sn: sessionStorage.sn,
      sjxssjtime: "",
      coundownTime: "",
      websocketUrl: process.env.VUE_APP_WEBSOCKET_URL,
      Noteven: false,
      listfather: [],
      statuszzm: "",
      wanchengtime: "",
      countdowntimemsg: "",
      endCause: "",
      mapUrl: '',
      robotssidname: '',
      stoptaskfalge: false,
      showsjxssj: false,
      wallListObj: {},
      director: '',
      setdirectortimer: null
    });
    let canvas: any = '';
    let map_info: any = '';
    let safe_area: any = ''
    let renderPoints: any = [];
    let canvasWidth = 922;
    let canvasHeight = 622;
    let mapOriginScale = 1;
    let mapScale = 1;
    let isMouseDown: Boolean = false;
    let lastPosition: any = {
      x: '',
      y: ''
    }
    let map_poinst: Array<any> = []
    let map_walls: Array<any> = []
    const methods = reactive({
      getssid: () => {
        robotssids(details.sn).then((resssid: any) => {
          console.log(resssid)
          let {
            data
          } = resssid
          details.robotssidname = data
        })
      },
      finishgetMap: async () => {
        const {
          params
        } = route
        mapInfo(params).then((async (res: any) => {
          console.log(res)
          if (res.code == 200) {
            details.mapUrl = 'data:image/png;base64,' + JSON.parse(res.data.map_info).map_png
            map_info = JSON.parse(res.data.map_info).map_info
            if (res.data.point != '') {
              map_poinst = JSON.parse(res.data.point)
            }
            // map_walls = res.data.wall
            // JSON.parse(res.data.wall).map((ele:any,index:number)=>{
            //   return ele.map((elsons:any,index:number)=>{
            //     return Number(elsons)*map_info.resolution
            //    })
            // })
            if (res.data.wall != '' && res.data.wall.length > 0) {
              map_walls = JSON.parse(res.data.wall).map((ele: any, index: number) => {
                return ele.map((elsons: any, index: number) => {
                  return Number(elsons) * map_info.resolution
                })
              })
            }
            if (res.data.safe_area != '' && res.data.safe_area.length > 0) {
              safe_area = JSON.parse(res.data.safe_area)
            }
            await methods.getxsmode()
            setTimeout(() => {
              methods.setCanvas()
            })
          }
          // if (res.code == 200) {
          //   const val = res.data
          //   if (val && val.length > 0) {
          //     val.map((item: any) => {
          //       const a = JSON.parse(item)
          //       console.log('a11', a)
          //       if (a.map_index == 0) {
          //         details.mapUrl = 'data:image/png;base64,' + a.map_png
          //         map_info = a.map_info
          //         setTimeout(()=>{
          //           methods.setCanvas()
          //         })

          //       }
          //     })
          //   }
          // }
        }))
      },
      async getxsmode() {
        if (details.taskmessge.task_type === 'explore_disinfect' || details.taskmessge.type === 'explore_disinfect') {
          return nowMapList({
            report: route.params.report,
            sn: details.sn
          }).then((res: any) => {
            res.data ? map_poinst = res.data : ''
          })
        }
      },
      getMap: () => {
        const params = {
          sn: details.sn,
          // mapname:'8433846b-dc2a-4c16-a261-0a515e7fc3b0'
          mapname: details.taskmessge.map_name,
          room_id: details.taskmessge.room_id
        }
        console.log(params, details.taskmessge)
        getmaps(params).then(((res: any) => {
          console.log(res)
          if (res.code == 200) {
            details.mapUrl = 'data:image/png;base64,' + res.data.png_map
            map_info = res.data.info
            if (res.data.point != '') {
              map_poinst = res.data.point
            }
            // map_walls = res.data.wall
            if (res.data.wall != '' && res.data.wall.length > 0) {
              map_walls = res.data.wall.map((ele: any, index: number) => {
                return ele.map((elsons: any, index: number) => {
                  return Number(elsons) * map_info.resolution
                })
              })
            }
            if (res.data.safe_area && res.data.safe_area != '' && res.data.safe_area.length > 0) {
              safe_area = res.data.safe_area
            }
            setTimeout(() => {
              methods.setCanvas()
            })
          }
        }))
      },
      getstatus: () => {
        // 成功后判断是否等于传过来的report不是则调任务详情接口 失败也一样
        currenttask(details.sn, {})
          .then((res: any) => {
            console.log(res.data.data);
            if (
              JSON.parse(res.data.data.statusinfo).content.report ===
              route.params.report
            ) {
              switch (JSON.parse(res.data.data.statusinfo).content.status) {
                case "start":
                  details.TaskCurrentStatus = "A";
                  if (
                    JSON.parse(res.data.data.statusinfo).content.phase &&
                    JSON.parse(res.data.data.statusinfo).content.phase.type ===
                    "explore" &&
                    JSON.parse(res.data.data.statusinfo).content.phase
                      .status === "start"
                  ) {
                    details.statustszxzz = "A";
                  } else if (
                    JSON.parse(res.data.data.statusinfo).content.phase &&
                    JSON.parse(res.data.data.statusinfo).content.phase.type ===
                    "dock" &&
                    JSON.parse(res.data.data.statusinfo).content.phase
                      .status === "start"
                  ) {
                    details.statustszxzz = "B";
                  } else if (
                    JSON.parse(res.data.data.statusinfo).content.phase &&
                    JSON.parse(res.data.data.statusinfo).content.phase.type ===
                    "leave_dock" &&
                    JSON.parse(res.data.data.statusinfo).content.phase
                      .status === "start"
                  ) {
                    details.statustszxzz = "C";
                  } else if (
                    JSON.parse(res.data.data.statusinfo).content.phase &&
                    JSON.parse(res.data.data.statusinfo).content.phase.type ===
                    "location" &&
                    JSON.parse(res.data.data.statusinfo).content.phase
                      .status === "start"
                  ) {
                    details.statustszxzz = "D";
                  } else {
                    details.statustszxzz = "";
                  }
                  break;
                case "finish":
                  details.TaskCurrentStatus = "C";
                  methods.gettaskInfo();
                  break;
                case "pause":
                  details.TaskCurrentStatus = "B";
                  break;
                // case 'created':
                //   this.TaskCurrentStatus = "A";
                // break;
                case "canceled":
                  details.TaskCurrentStatus = "D";
                  break;
                case "failed":
                  details.TaskCurrentStatus = "D";
                  break;
              }
              // 如果是当前任务拿去所有数据 否则
              details.taskmessge = JSON.parse(res.data.data.statusinfo).content;
              details.taskmessge.operator = res.data.data.operator
              details.taskmessge.room_name = res.data.data.room_name
              details.taskmessge.room_id = res.data.data.room_id
              methods.gettaskInfo();
              if (res.data.data.type === 'deploy_disinfect') { // 判断是现有地图
                methods.getMap()
              }
            } else {
              methods.huoqustatus();
            }
          })
          .catch((err: any) => {
            console.log(err, route.params.report);
            methods.huoqustatus();
          });
      },
      getCaption: (obj: number) => {
        // const index: any = obj.lastIndexOf(" ");
        // return obj.substring(index + 1, obj.length);
        return obj > 0 ? moment(obj * 1000).format(momentStamp('time')) : '--'
      },
      newdateshuju(starttime: number, endtime: number): number {
        const abb = new Date(starttime).getTime()
        const add = new Date(endtime).getTime()
        let diff = (add - abb) / 1000;
        return diff < 0 ? 0 : diff;
      },
      huoqustatus: () => {
        taskstatus(route.params).then((reportres: any) => {
          console.log(reportres.data);
          switch (reportres.data.status) {
            // 正在进行
            case "start":
              details.TaskCurrentStatus = "A";
              break;
            // 完成状态
            case "finish":
              details.TaskCurrentStatus = "C";
              details.statuszzm = "chenggong";
              break;
            // 暂停
            case "pause":
              details.TaskCurrentStatus = "B";
              break;
            case "created":
              details.TaskCurrentStatus = "A";
              break;
            // 中断
            case "canceled":
              details.TaskCurrentStatus = "D";
              details.statuszzm = "shibai";
              details.endCause = methods.formsendstatus(
                reportres.data
              );
              break;
            // 结束
            case "failed":
              details.TaskCurrentStatus = "D";
              details.statuszzm = "shibai";
              details.endCause = methods.formsendstatus(
                reportres.data
              );
              break;
          }
          details.showsjxssj = false;
          details.taskmessge = reportres.data;
          if (
            details.taskmessge.status === "finish" ||
            details.taskmessge.status === "canceled" ||
            details.taskmessge.status === "failed"
          ) {
            details.showsjxssj = true;
            // console.log(this.formateSeconds(this.taskmessge.time_end))
            details.sjxssjtime = methods.formateSeconds(
              details.taskmessge.disinfect_time
            );
            // details.coundownTime = methods.formateSeconds(
            //   (new Date(details.taskmessge.time_end).getTime() -
            //     new Date(details.taskmessge.time_start).getTime()) /
            //   1000
            // );
            details.coundownTime = methods.formateSeconds(details.taskmessge.time_end - details.taskmessge.time_start)
            // details.coundownTime = details.taskmessge.time_end - details.taskmessge.time_start
            details.wanchengtime = details.taskmessge.time_end;
            //   this.getMap();
          }
          // methods.getMap()
          methods.finishgetMap()
          methods.gettaskInfo();
        });
      },
      gettaskInfo: () => {
        taskInfo(route.params).then((resreport: any) => {
          //   console.log(JSON.parse(resreport.data))
          details.listfather = methods.setaddphaselist(resreport.data);
          console.log(details.listfather);
        });
      },
      //获取现有地图历史点列表
      getNowMapList() {
        console.log(map_poinst)
        let lists: any[] = []
        for (let a = 0; a < map_poinst.length; a++) {
          let item = map_poinst[a]
          if (item.action == "nav" || item.action == "disinfect" || item.action == "dock") {
            item.index = item.name ? Number(item.name) : Number(item.idx)
            lists.push(item)
          }
        }
        if (lists.length > 0) {
          // setTimeout(() => {
          // console.log('renderPoints', renderPoints, this.newList)
          methods.renderRobotDeployList(lists)
          canvas.renderAll()
          // }, 200)
        }
        // nowMapList({
        //   report: route.params.report,
        //   sn: details.sn
        // }).then((res: any) => {
        //   console.log('res.data', res.data)
        //   let lists: any[] = []
        //     for (let a = 0; a < res.data.length; a++) {
        //         let item = res.data[a]    
        //         if (item.action == "nav" || item.action == "disinfect"|| item.action == "dock") {
        //             item.index = item.point_index+1
        //             lists.push(item)
        //         }
        //     }
        //   if (lists.length > 0) {
        //     // setTimeout(() => {
        //     // console.log('renderPoints', renderPoints, this.newList)
        //     methods.renderRobotDeployList(lists)
        //     canvas.renderAll()
        //     // }, 200)
        //   }

        // });
      },
      //获取地图点列表
      // getReportList: () => {
      //   reportList({
      //     report: route.params.report,
      //     sn: details.sn
      //   }).then((resreport: any) => {
      //     console.log('getReportList', resreport.data)
      //     let renderList = resreport.data
      //     let list = renderList.reverse()
      //     let finishList: any[] = []
      //     for (let a of list) {
      //       finishList.push(a)
      //     }
      //     let lists: any[] = []
      //     for (let a = 0; a < finishList.length; a++) {
      //       let item = finishList[a]
      //       item.index = item.point_index+1
      //       lists.push(item)
      //     }
      //     let newList: any[] = []
      //     for (let a of lists) {
      //       if (!methods.isArrHas(renderPoints, a)) {
      //         renderPoints.push(a)
      //         newList.push(a)
      //       }
      //     }
      //     console.log('newList',newList)
      //     if (newList.length > 0) {
      //       // setTimeout(() => {
      //       // console.log('renderPoints', renderPoints, this.newList)
      //       methods.renderRobotDeployList(newList)
      //       canvas.renderAll()
      //       // }, 200)
      //     }

      //   });
      // },
      //计算每个点位的横像素坐标
      calcWallX(x: any) {
        let wid = ((x - map_info.origin.x) / map_info.resolution)
        return wid
      },
      calcWallY(y: any) {
        let hei = map_info.height - 1 - ((y - map_info.origin.y) / map_info.resolution)
        return hei
      },
      //计算每个点位的横像素坐标
      calcX(item: any, mapInfo: any) {
        console.log(item)
        let wid = ((item.x - mapInfo.origin.x) / mapInfo.resolution)


        return wid
      },
      //计算每个点位的纵像素坐标
      calcY(item: any, mapInfo: any) {
        let hei = mapInfo.height - 1 - ((item.y - mapInfo.origin.y) / mapInfo.resolution)
        return hei
      },
      makePixInfo(rawPos: any, mapInfo: any) {
        return {
          x: methods.calcX(rawPos, mapInfo),
          y: methods.calcY(rawPos, mapInfo),
          angle: 0
        }
      },
      //初始化的时候获取部署点列表
      renderRobotDeployList(list: any) {
        if (!map_info.origin) {
          return
        }
        list.forEach((point: any) => {
          if (point.action == "disinfect" || point.action == "") { //消杀点
            let rawInfo = {
              "x": point.x,
              "y": point.y,
              "theta": point.theta,
              "orient_w": 0,
              "orient_z": 0
            }
            let pixInfo = methods.makePixInfo(rawInfo, map_info)
            console.log(point, 'pointsndis')
            methods.addUvPoint(
              pixInfo.x,
              pixInfo.y,
              pixInfo.angle,
              point.index ? point.index : point.point_index + 1,
              'disinfect',
              rawInfo
            )

          } else if (point.action == "nav") { //导航点
            let rawInfo = {
              "x": point.x,
              "y": point.y,
              "theta": point.theta,
              "orient_w": 0,
              "orient_z": 0
            }
            let pixInfo = methods.makePixInfo(rawInfo, map_info)
            console.log(point, 'pointsnava')
            methods.addNavPoint(
              pixInfo.x,
              pixInfo.y,
              pixInfo.angle,
              point.index ? point.index : point.point_index + 1,
              'nav',
              rawInfo
            )
          }
        });
        setTimeout(() => {
          list.forEach((point: any) => {
            if (point.action == "dock" || point.phase_type == "navigation_dock") { //充点电
              let rawInfo = {
                "x": point.x,
                "y": point.y,
                "theta": point.theta,
                "orient_w": 0,
                "orient_z": 0
              }
              let pixInfo = methods.makePixInfo(rawInfo, map_info)
              methods.addBatteryPoint(
                pixInfo.x,
                pixInfo.y,
                pixInfo.angle,
                point.index,
                'dock',
                rawInfo
              )
            }
          })
        }, 100);
      },
      isArrHas(arr: any, item: any) {
        for (let a of arr) {
          if (item.ID == a.ID) {
            return true
          }
        }
        return false
      },
      addUvPoint(x: any, y: any, angle: any, index: any, type: any, rawInfo: any) {

        fabric.Image.fromURL(require('@/assets/images/map/disfinect.png'), (img: any) => {
          let img1 = img.scale(0.4).set({
            left: 0,
            top: 0,
            originX: 'center',
            originY: 'center'
          });

          let circle = new fabric.Circle({
            radius: 9,
            fill: "#05B4BC",
            originX: 'center',
            originY: 'center'
          });

          let text = new fabric.Text(index + '', {
            fontSize: 16,
            originX: 'center',
            originY: 'center',
            fill: "#05B4BC",
            fontStyle: 'normal',
            top: -5
          });

          let group = new fabric.Group([img1, text], {
            originX: 'center',
            originY: 'center',
            left: x,
            top: y,
            angle: 0,
            lockMovementX: true,
            lockMovementY: true
          });
          group.set("type", "disinfect")
          group.set("idx", index)
          group.set("rawInfo", rawInfo)
          group.scale(1.5 / mapScale)
          // console.log(1.5 / mapScale,mapScale,'mapScaledis')
          group.set("selectable", false)
          canvas.add(group);
        });
      },
      addNavPoint(x: any, y: any, angle: any, index: any, type: any, rawInfo: any) {
        fabric.Image.fromURL(require('@/assets/images/map/nav.png'), (img: any) => {
          let img1 = img.scale(0.4).set({
            left: 0,
            top: 0,
            originX: 'center',
            originY: 'center'
          });
          let circle = new fabric.Circle({
            radius: 9,
            fill: "#8C8C8C",
            originX: 'center',
            originY: 'center'
          });

          let text = new fabric.Text(index + '', {
            fontSize: 16,
            originX: 'center',
            originY: 'center',
            fill: "#8C8C8C",
            fontStyle: 'normal',
            top: -5
          });
          let group = new fabric.Group([img1, text], {
            originX: 'center',
            originY: 'center',
            left: x,
            top: y,
            angle: 0,
            lockMovementX: true,
            lockMovementY: true
          });
          group.set("type", "nav")
          group.set("idx", index)
          group.set("rawInfo", rawInfo)
          group.scale(1.5 / mapScale)
          // console.log(1.5 / mapScale,mapScale,'mapScalenav')
          group.set("selectable", false)
          canvas.add(group);

        });
      },
      addBatteryPoint(x: any, y: any, angle: any, index: any, type: any, rawInfo: any) {
        fabric
          .Image
          .fromURL(require('@/assets/images/map/battery.png'), (point: any) => {
            point.originX = 'center'
            point.originY = 'center'
            point.top = y
            point.left = x
            point.scaleX = 0.5
            point.scaleY = 0.5
            let group = new fabric.Group([
              point
            ], {
              originX: 'center',
              originY: 'center',
              left: x,
              top: y,
              angle: 0,
              lockMovementX: true,
              lockMovementY: true,
            });
            group.set("type", type)
            group.set("idx", index)
            group.set("rawInfo", rawInfo)
            group.set("zIndex", 2)
            group.scale(1.5 / mapScale)
            group.set("selectable", false)
            // canvas.sendBackwards(point)
            canvas.add(group);

          })
      },
      setaddphaselist: (list: any = []) => {
        list.forEach((element: any, index: number) => {
          typeof list[index] === "string" ?
            (list[index] = JSON.parse(list[index])) :
            (list[index] = list[index]);
        });
        const config: any = {
          disinfect: {
            icon: true,
            index: true
          },
          navigation: {
            icon: true,
            index: false
          },
          explore: {
            icon: true,
            index: false
          },
          dock: {
            icon: true,
            index: false
          },
          leave_dock: {
            icon: true,
            index: false
          },
          navigation_dock: {
            icon: true,
            index: false
          },
          location: {
            icon: true,
            index: false
          },
          sleep: {
            icon: true,
            index: true
          },
          navigation_origin: {
            icon: true,
            index: false
          },
          charging: {
            icon: true,
            index: false
          },
        };

        function match_same_phase(p1: any, p2: any) {
          //检查是否是一个过程被暂停过
          return (
            p1.point_index == p2.point_index && p1.phase_type == p2.phase_type
          );
        }

        function mark_same_phase(phase: any, conf: any) {
          phase.showFlag = conf && conf.icon;
          phase.disinfectindexs = conf && conf.index ? phase.point_index : null;
        }

        function mark_video_phase_flag(phase: any, video: any = null) {
          if (video && video.flags & 1) {
            phase.showVideo = true;
            phase.showVideoIndex = video.phase_index;
            return true;
          }
          phase.showVideo = false;
          return;
        }

        const history =
          details.TaskCurrentStatus != "A" && details.TaskCurrentStatus != "B";
        let last_index = -1;
        let last_video_index = -1;
        for (let i = list.length - 1; i >= 0; --i) {
          const phase: any = list[i];
          if (!config[phase.phase_type]) continue; //过滤不必要的数据

          if (
            last_index != -1 &&
            !match_same_phase(list[i], list[last_index])
          ) {
            mark_same_phase(
              list[last_index],
              config[list[last_index].phase_type]
            );
            if (last_video_index != -1) {
              mark_video_phase_flag(list[last_index], list[last_video_index]);
            }
            last_video_index = i;
          } else if (last_index != -1) {
            mark_same_phase(list[last_index], null);
          } else {
            last_video_index = i;
          }
          last_index = i;
        }
        if (last_index != -1) {
          mark_same_phase(
            list[last_index],
            config[list[last_index].phase_type]
          );
          if (
            !history && ["start", "pause"].indexOf(list[last_index].status) != -1
          ) {
            mark_video_phase_flag(list[last_index], null);
          } else if (last_video_index != -1) {
            mark_video_phase_flag(list[last_index], list[last_video_index]);
          }
        }
        return list;
      },
      addphaseList(phase: any, time: any, point_index: any) {
        if (phase === null) {
          return;
        }
        if (
          details.listfather.length &&
          details.listfather[0].phase_index ===
          phase.phase_index
        ) {
          details.listfather[0].phase_type === "" ?
            (details.listfather[0].phase_type =
              phase.type) :
            "";
          details.listfather[0].status =
            phase.status;
          if (phase.detail != "") {
            details.listfather[0].detail =
              phase.detail;
          }
          details.listfather[0].time_end = time;
          if (phase.type === "pause" && phase.status === "start") {
            details.listfather[0].time_start = time;
          }
          details.listfather[0].flags |=
            phase.flags;
        } else {
          details.listfather.unshift(
            Object.assign({
              time_start: time,
              point_index: point_index,
              phase_type: phase.type,
            },
              phase
            )
          );
        }
        details.listfather = methods.setaddphaselist(details.listfather);
      },
      goOnTask() {
        Operationtasks(details.taskmessge.name, "resume", details.sn, {}).then(
          (res: any) => { }
        );
      },
      jumpTo() {
        console.log(details)
        router.push({
          path: "/robot/robotInfo/" + details.sn
        })
      },
      goDisinfect() {
        let data = localStorage.getItem("searchData") || ''
        console.log('searchDatadaaaa', data)
        router.push({
          // name:'About',
          name: 'disinfect',
          params: {
            searchData: data
          }
        })
      },
      pasuetask() {
        if (details.statustszxzz === 'B' || details.statustszxzz === 'C') {
          return
        }
        Operationtasks(details.taskmessge.name, "pause", details.sn, {}).then(
          (res: any) => { }
        );
      },
      formstatus(newval: any) {
        if (newval.detail != "" && newval.detail === "safeguard") {
          return i18n.global.t('disinfect.aqsj');
        } else if (newval.detail != "" && newval.detail === "manual") {
          return i18n.global.t('disinfect.sdzt');
        } else if (newval.detail != "" && newval.detail === "safeguard_pir") {
          if (newval.status === 'failed') {
            console.log('1')
            return i18n.global.t('disinfect.PIRztcs');
          } else {
            return i18n.global.t('disinfect.pirzt');
          }
        } else if (newval.detail != "" && newval.detail === "safeguard_ai") {
          return '摄像头触发';
        } else if (
          newval.detail != "" &&
          newval.detail === "safeguard_access_control"
        ) {
          if (newval.status === 'failed') {
            return i18n.global.t('disinfect.mjpztcs');
          } else {
            return i18n.global.t('disinfect.mjphd');
          }
        } else if (newval.detail != "" && newval.detail === "access_control") {
          console.log(newval)
          if (newval.status === 'failed') {
            console.log('我断连超时')
            return i18n.global.t('disinfect.mjpdlcs');
          } else {
            return i18n.global.t('disinfect.mjpdl');
          }
        } else if (newval.detail != "" && newval.detail === "high_temperature") {
          return i18n.global.t('disinfect.wdbhztrw');
        }
      },
      stoptask() {
        Operationtasks(
          details.taskmessge.name,
          "terminate",
          details.sn, {}
        ).then((res: any) => {
          // console.log(res.data)
          if (res && JSON.parse(res.data).content.code === 0) {
            details.stoptaskfalge = false
          } else {
            ElMessage({
              type: "error",
              message: i18n.global.t('disinfect.czsbl'),
            });
          }
        }).catch(err => {
          ElMessage({
            type: "error",
            message: i18n.global.t('disinfect.czsbl'),
          });
        })
      },
      TaskAssStatus(detailStatus: any) {
        details.statustszxzz = "";
        if (detailStatus.phase && detailStatus.phase.type === "countdown") {
          details.countdowntimemsg = detailStatus.phase.detail;
        } else {
          details.countdowntimemsg = "";
        }
        // console.log(detailStatus.status, detailStatus,detailStatus.phase.status);
        if (detailStatus.type != "") {
          details.taskmessge.type = detailStatus.type;
        }
        if (detailStatus.status === "start") {
          details.showsjxssj = false;
          details.TaskCurrentStatus = "A";
          if (detailStatus.phase && detailStatus.phase.type === "explore") {
            details.statustszxzz = "A";
            // } else if (detailStatus.phase&&detailStatus.phase.type === 'dock'&&detailStatus.phase.status==='start') {
          } else if (detailStatus.phase && detailStatus.phase.type === "dock") {
            details.statustszxzz = "B";
          } else if (
            detailStatus.phase &&
            detailStatus.phase.type === "leave_dock"
          ) {
            details.statustszxzz = "C";
          } else if (
            detailStatus.phase &&
            detailStatus.phase.type === "location"
          ) {
            details.statustszxzz = "D";
          } else {
            details.statustszxzz = "";
          }
        } else if (detailStatus.status === "pause") {
          details.showsjxssj = false;
          details.TaskCurrentStatus = "B";
        } else if (
          detailStatus.status === "canceled" ||
          detailStatus.status === "failed"
        ) {
          //   clearInterval(this.timer);
          //   this.timer = null;
          // console.log((new Date(detailStatus.time).getTime()/1000)-new Date(details.taskmessge.time_start).getTime()/1000,new Date(details.taskmessge.time_start).getTime()/1000,'222')
          details.coundownTime = methods.formateSeconds(
            // (new Date(detailStatus.time).getTime() -
            //   new Date(details.taskmessge.time_start).getTime()) /
            // 1000
            detailStatus.end_time ? (detailStatus.end_time - details.taskmessge.time_start) : (new Date(detailStatus.time).getTime() / 1000) - (details.taskmessge.time_start_ts ? Number(details.taskmessge.time_start_ts) : new Date(details.taskmessge.time_start).getTime() / 1000)
          );
          details.wanchengtime = detailStatus.time_ts;
          details.statuszzm = "shibai";
          details.TaskCurrentStatus = "D";
          details.showsjxssj = true;
          details.sjxssjtime = methods.formateSeconds(detailStatus.info.used);
          details.endCause = methods.formsendstatus(detailStatus);
          //   this.getMap();
        } else if (detailStatus.status === "finish") {
          //   clearInterval(this.timer);
          //   this.timer = null;
          console.log(new Date(detailStatus.time).getTime(), new Date(details.taskmessge.time_start).getTime(), '11我在找原因')
          details.coundownTime = methods.formateSeconds(
            (new Date(detailStatus.time).getTime() -
              new Date(details.taskmessge.time_start).getTime()) /
            1000
          );
          details.statuszzm = "chenggong";
          details.TaskCurrentStatus = "C";
          details.showsjxssj = true;
          details.sjxssjtime = methods.formateSeconds(detailStatus.info.used);
          details.wanchengtime = detailStatus.time_ts;
          //   this.getMap();
        }
      },
      formsendstatus: (detailStatus: any): string => {
        console.log(detailStatus)
        if (detailStatus.detail === "") {
          return '--';
        }
        if (detailStatus.detail === "safeguard") {
          return i18n.global.t("minbox.aqsjzt");
        } else if (detailStatus.detail === "manual") {
          return i18n.global.t("minbox.sdjieshu");
        } else if (detailStatus.detail === "access_control_stop") {
          return i18n.global.t("minbox.mjtz");
        } else if (detailStatus.detail === "push_on") {
          return i18n.global.t("minbox.stchufa");
        } else if (detailStatus.detail === "safeguard_pir") {
          return i18n.global.t("minbox.pirjs");
        } else if (detailStatus.detail === "safeguard_ai") {
          return i18n.global.t("minbox.sxtjs");
        } else if (detailStatus.detail === "safeguard_access_control") {
          return i18n.global.t("minbox.mjjs");
        } else if (detailStatus.detail === "access_control") {
          return i18n.global.t("minbox.mjdl");
        } else if (detailStatus.detail === "geomagnetic") {
          return i18n.global.t("minbox.dici");
        } else if (detailStatus.detail === "anticollision") {
          return i18n.global.t("minbox.fangzt");
        } else if (detailStatus.detail === "chassis") {
          return i18n.global.t("minbox.dlguoji");
        } else if (detailStatus.detail === "fall_into_cliff_danger") {
          return i18n.global.t("minbox.xyjc");
        } else if (
          detailStatus.detail === "device_error" ||
          detailStatus.detail === "diagnosis"
        ) {
          return i18n.global.t("minbox.sbgz");
        } else if (
          detailStatus.detail === "fail_zero_arrived" ||
          detailStatus.detail === "fail_target_point_unreachable" ||
          detailStatus.detail === "fail_uslam_unable_navigation" ||
          detailStatus.detail === "fail_uslam_navi_no_way"
        ) {
          return i18n.global.t("minbox.daohangshibai");
        } else if (detailStatus.detail === "fail_relocation_faild") {
          return i18n.global.t("minbox.dingweishibai");
        } else if (
          detailStatus.detail === "fail_uslam_unable_auto_mapping" ||
          detailStatus.detail === "fail_fov_not_fit"
        ) {
          return i18n.global.t("minbox.jiantushibai");
        } else if (detailStatus.detail === "fail_leave_dock") {
          return i18n.global.t("minbox.xiazhuangsb");
        } else if (detailStatus.detail === "fail_dock") {
          return i18n.global.t("minbox.shangzhuangsb");
        } else if (detailStatus.detail === "sensor_error") {
          return i18n.global.t("minbox.cgqyc");
        } else if (detailStatus.detail === "fail_slam_json_error") {
          return i18n.global.t("minbox.dhcscw");
        } else if (detailStatus.detail === "fail_slam_http_error") {
          return i18n.global.t("minbox.dhfwqcw");
        } else if (detailStatus.detail === "fail_target_point_unreachable") {
          return i18n.global.t("minbox.mbdbkd");
        } else if (detailStatus.detail === "fail_uslam_not_init") {
          return i18n.global.t("minbox.dhmkcshsb");
        } else if (detailStatus.detail === "fail_dock_recognise") {
          return i18n.global.t("minbox.zbdcdz");
        } else if (detailStatus.detail === "fail_interrupt_abnormal") {
          return i18n.global.t("minbox.yczd");
        } else if (detailStatus.detail === 'emergency_stop') {
          return i18n.global.t("minbox.bentijt");
        } else if (detailStatus.detail === 'fail_elevator') {
          return i18n.global.t("disinfect.chengtisb");
        } else {
          return i18n.global.t("system.abnormalEnd");
        }
      },
      goBack: () => {
        router.push({
          path: "/robot/robotInfo/" + details.sn
        });
      },
      openWebsocket() {
        const params = {
          robotsn: details.sn,
          topic: "notify",
          unsubscribe: false,
        };
        const val = JSON.stringify(params);
        if ("WebSocket" in window) {
          // websocket = new WebSocket(state.websocketUrl)

          websocket = new WebSocket(
            details.websocketUrl + "?token=" + useStore().state.user.token
          );
        } else {
          // console.log('Not support websocket')
        }
        //成功回调
        websocket.onopen = function (event: any) {
          console.log("机器人详情websocket连接成功！");
          websocket.send(val);
          console.log("val=>", val);
        };
        //事件监听
        websocket.onmessage = function (event: any) {
          if (event.data == "ping") {
            websocket.send("pong");
            return;
          }
          let data: any = {};
          const dataType = typeof event.data == "string";
          if (event.data && dataType) {
            data = JSON.parse(event.data);
          }
          // 获取任务状态
          if (data.content && data.title == "notify_task_status") {
            console.log("机器人详情event=>", data);
            if (data.content.report != route.params.report) {
              return;
            }
            methods.TaskAssStatus(data.content);
            methods.addphaseList(
              data.content.phase,
              // data.content.time,
              data.content.time_ts,
              data.content.info.points_index
            );
            if (data.content.info.points_index >= 0) {
              // methods.getNowMapList()
            }

          }
        };
        websocket.onclose = function () {
          console.log("机器人详情websocket关闭！");
          if (details.Noteven) {
            console.log("我已经关闭了");
            return;
          }
          methods.openWebsocket();
        };
      },
      formateSeconds(endTime: any = 0) {
        let secondTime: any = Math.floor(endTime); //将传入的秒的值转化为Number
        let min: any = 0; // 初始化分
        let h: any = 0; // 初始化小时
        let result: any = "";
        if (secondTime >= 60) {
          //如果秒数大于60，将秒数转换成整数
          min = Math.floor(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
          secondTime = Math.floor(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
          if (min > 60) {
            //如果分钟大于60，将分钟转换成小时
            h = Math.floor(min / 60); //获取小时，获取分钟除以60，得到整数小时
            min = Math.floor(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
          }
        } else if (secondTime < 0) {
          secondTime = 0
        }
        result = `${h.toString().padStart(2, "0")}:${min
          .toString()
          .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
        return result;
      },
      setCanvas() {
        const canvasBox: any = document.getElementById('canvasBox');
        console.log('canvasWidth', canvasBox)
        canvasWidth = canvasBox.offsetWidth;
        // let height = width * map_info.height / map_info.width
        canvas = new fabric.Canvas('map-canvas', {
          backgroundColor: '#DDF0F9',
          width: canvasWidth,
          height: canvasHeight,
          selectable: false
        });
        canvas.selection = false
        canvas.preserveObjectStacking = true;
        methods.drawBackgroundImg(details.mapUrl)
        nextTick(() => {
          safe_area.length > 0 ? methods.setsafe_areaLine(map_info, safe_area) : ''
          methods.bindEvents()
          methods.getNowMapList()
          methods.receiveMap_wall()
        })
        details.setdirectortimer = setInterval(() => {
          methods.getnewpos()
        }, 1000)
      },
      setsafe_areaLine(info: any, safeList: any) {
        console.log(safeList)
        safeList.forEach((item: any, index: number) => {
          const newlist = item.data.length > 0 ? item.data.map((one: any, i: any) => { return methods.safeinfo(one, i, info) }) : []
          console.log(newlist, 'newlist')
          let newlistsafe = [] as any
          let safalist = [] as any
          newlist.map((ele: any, eleindex: number) => {
            if (eleindex % 2 == 0) {
              newlistsafe.push({
                x: ele
              })
            } else {
              newlistsafe.push({
                y: ele
              })
            }
          })

          newlistsafe.forEach((safeele, index) => {
            if (index % 2 == 0) {
              safalist.push(Object.assign(safeele, newlistsafe[index + 1]))
            }
          })
          console.log(safalist)
          var rect = new fabric.Polygon(safalist, {
            fill: 'rgba(60,179,113,0.4)', // 填充色
            stroke: '#fff', // 线段颜色：紫色
            strokeWidth: 0, // 线段粗细 5
            selectable: false
          })
          // rect.set('type', 'wallLine')
          canvas.add(rect);
        })
      },
      safeinfo(point: any, i: number, mapInfo: any) {
        const isX = i % 2 == 0 // 判断是x还是y
        return isX ? (point - mapInfo.origin.x) / mapInfo.resolution :
          -((point - mapInfo.origin.y) / mapInfo.resolution - mapInfo.height)
      },
      getnewpos() {
        getpos({
          sn: details.sn
        }).then((res: any) => {
          //  canvas.forEachObject((obj:any) => {
          //        if (obj && obj.type && obj.type === 'director') {
          //             canvas.remove(obj)
          //             canvas.renderAll()
          //       }
          //   });
          // if (details.TaskCurrentStatus === 'C'||details.TaskCurrentStatus === 'D') {
          //   clearInterval(details.setdirectortimer)
          //   details.setdirectortimer = null
          //   return
          // }
          console.log(res)
          let point = res.data
          let rawInfo = {
            "x": point.x,
            "y": point.y,
            "theta": point.theta,
            "orient_w": 0,
            "orient_z": 0
          }
          let pixInfo = methods.makePixInfo(rawInfo, map_info)
          methods.updateDirector(
            pixInfo.x,
            pixInfo.y,
            'director',
            rawInfo
          )
        })
      },
      updateDirector(x: any, y: any, type: any, rawInfo: any) {
        fabric.Image.fromURL(require('@/assets/images/map/director.png'), (img: any) => {
          let img1 = img.scale(0.4).set({
            left: 0,
            top: 0,
            originX: 'center',
            originY: 'center'
          });
          let group = new fabric.Group([img1], {
            originX: 'center',
            originY: 'center',
            left: x,
            top: y,
            angle: -(rawInfo.theta * 180) / Math.PI,
            lockMovementX: true,
            lockMovementY: true
          });
          group.set("type", type)
          group.set("rawInfo", rawInfo)
          group.scale(1.5 / mapScale)
          group.set("selectable", false)
          canvas.forEachObject((obj: any) => {
            if (obj && obj.type && obj.type === 'director') {
              canvas.remove(obj)
              canvas.renderAll()
            }
          });
          if (details.TaskCurrentStatus === 'C' || details.TaskCurrentStatus === 'D') {
            clearInterval(details.setdirectortimer)
            details.setdirectortimer = null
            return
          }
          canvas.add(group);
        });
      },
      getUuid() {
        var s: any = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        let uuid: string = s.join("");
        return uuid;
      },
      receiveMap_wall() {
        console.log('我执行了虚拟墙')
        if (map_walls.length == 0) {
          return
        }
        methods.getVitrualWallData(map_walls)
      },
      getVitrualWallData(walls: Array<any>) {
        let virtual_wall = walls
        // let virtual_wall= [[-2.9329248385261444, 2.2944106860842233, -5.376620214359069, -0.2048236543731985],
        // [-3.988156268157285, -0.9268251097863356, -2.766309215828983, 0.4060998717909552]]
        console.log(virtual_wall)
        for (let a of virtual_wall) {
          let arr: any[] = []
          for (let b = 0; b < (a.length / 2); b++) {
            arr.push({
              uid: methods.getUuid(),
              x: methods.calcWallX(a[2 * b]),
              y: methods.calcWallY(a[2 * b + 1])
            })
          }
          console.log(arr)
          let key: string = methods.getUuid()
          details.wallListObj[key] = arr
        }
        methods.setVitrualWall()
      },
      setVitrualWall() {
        for (let key in details.wallListObj) {
          let arr = details.wallListObj[key]
          for (let a = 0; a < arr.length; a++) {
            if (a + 1 < arr.length) {
              let line = new fabric.Line([arr[a].x, arr[a].y, arr[a + 1].x, arr[a + 1].y], {

                strokeWidth: 2, //线宽
                strokeDashArray: [3, 1],

                stroke: 'rgba(0,0,0,0.8)', //线的颜色

                selectable: false

              });
              console.log('wallLine', [arr[a].x, arr[a].y, arr[a + 1].x, arr[a + 1].y])
              line.set("lineId", key)
              line.set("type", "wallLine")
              canvas.add(line);
            }

          }
        }
        canvas.forEachObject(obj => {
          if (obj && obj.type && obj.type == 'wallPoint') {
            canvas.bringForward(obj)
          }
          if (obj && obj.type && obj.type == 'wallLine') {
            canvas.sendToBack(obj)
          }
        });
      },
      bindEvents() {
        canvas.on({
          'mouse:down': methods.handleOnMouseDown,
          'mouse:move': methods.handleOnMouseMove,
          'mouse:up': methods.handleOnMouseUp,
        });
        canvas.getZoom = function (params: any) {
          const {
            scaleX
          } = fabric
            .util
            .qrDecompose(this.viewportTransform);
          return scaleX;
        };
      },
      handleOnMouseDown(opt: any) {
        console.log('handleOnMouseDown', opt);
        let hasMoved = false
        const {
          absolutePointer
        } = opt;
        lastPosition = {
          x: absolutePointer.x,
          y: absolutePointer.y
        }
        let mouseFrom = opt.absolutePointer
        isMouseDown = true

      },
      handleOnMouseUp() {
        isMouseDown = false
      },
      handleOnMouseMove(opt: any) {
        if (!isMouseDown) {
          return
        }
        const {
          absolutePointer
        } = opt;
        const {
          x,
          y
        } = lastPosition;
        let px = absolutePointer.x - x;
        let py = absolutePointer.y - y;
        if (Math.abs(px) > 50) {
          if (px > 0) {
            px = 50
          } else {
            px = -50
          }
        }
        if (Math.abs(py) > 50) {
          if (py > 0) {
            py = 50
          } else {
            py = -50
          }
        }
        // 拷贝
        const vpt = canvas.viewportTransform.slice(0);
        vpt[4] += px;
        vpt[5] += py;

        canvas.setViewportTransform(vpt);
        canvas.requestRenderAll();
      },
      drawBackgroundImg(mapSrc: any) {
        fabric.Image.fromURL(mapSrc, (bgImg: any) => {
          let clientWidth = canvasWidth
          let clientHeight = canvasHeight
          const {
            width,
            height
          } = bgImg;
          // console.log(width,height,clientWidth,clientHeight,(width > clientWidth || height > clientHeight),'width > clientWidth')
          let scale,
            scaleX,
            scaleY;
          if (width > clientWidth || height > clientHeight) {
            // 缩小显示
            // console.log('缩小',clientWidth / width,clientHeight / height)
            scaleX = clientWidth / width;
            scaleY = clientHeight / height;
          } else {
            //  console.log('放大',clientWidth / width,clientHeight / height)
            // 放大显示
            scaleX = clientWidth / width;
            scaleY = clientHeight / height;
          }
          scale = scaleX <= scaleY ?
            scaleX :
            scaleY;
          const left = (clientWidth - width) / 2;
          const top = (clientHeight - height) / 2;
          // 设置背景图片在左上角
          bgImg.set({
            left: 0,
            top: 0
          });
          // 拷贝vpt
          const vpt = canvas.viewportTransform.slice(0);
          // 将视图调整至中间。注意：需要将vpt[0]、vpt[3]置为1，否则会有偏差
          vpt[0] = 1
          vpt[3] = 1
          vpt[4] = left
          vpt[5] = top
          canvas.setViewportTransform(vpt);
          canvas.zoomToPoint({
            x: clientWidth / 2,
            y: clientHeight / 2
          }, scale);
          mapOriginScale = scale
          mapScale = scale
          canvas.setBackgroundImage(bgImg, canvas.renderAll.bind(canvas));
        })
      },
      addScale() {
        methods.scale_canvas(-100, canvasWidth / 2, canvasHeight / 2)
      },
      minScale() {
        methods.scale_canvas(100, canvasWidth / 2, canvasHeight / 2)
      },
      scale_canvas(deltaY: any, offsetX: any, offsetY: any) {
        let zoom = canvas.getZoom();
        zoom *= 0.999 ** deltaY; // 两个*表示幂运算
        if (zoom > 5)
          zoom = 5;
        if (zoom < mapOriginScale)
          zoom = mapOriginScale;

        canvas.zoomToPoint({
          x: offsetX,
          y: offsetY
        }, zoom);
        mapScale = zoom
        methods.updatePointsPostion()
      },
      //因为地图大小实时变化，点位的坐标也要更新
      updatePointsPostion() {
        canvas.forEachObject((obj: any) => {
          if (obj && obj.type && (obj.type === 'disinfect' || obj.type === 'dock' || obj.type ===
            'nav' || obj.type === 'director')) {
            let rawInfo = obj.rawInfo
            let mapPos = {
              "x": rawInfo.x,
              "y": rawInfo.y,
              "theta": rawInfo.theta
            }
            let pixPos = methods.makePixInfo(mapPos, map_info)
            obj.top = pixPos.y
            obj.left = pixPos.x
            obj.angle = pixPos.angle
            obj.scale(1.5 / mapScale)
            obj.set("selectable", false)
          }
          if (obj && obj.type && (obj.type === 'wallPoint' || obj.type === 'temp')) {
            obj.scale(1.5 / mapScale)
          }
          if (obj && obj.type && obj.type === 'wallLine') {
            obj.set("strokeWidth", 1.5 / mapScale)
          }
        });
      },
    });
    onMounted(() => {
      // details.websocketUrl = `ws://${window.location.host}/v1/channel`
      methods.getstatus();
      methods.openWebsocket();
      methods.getssid()
    });
    onBeforeUnmount(() => {
      details.Noteven = true;
      websocket.close();
      if (details.setdirectortimer) {
        clearInterval(details.setdirectortimer)
        details.setdirectortimer = null
      }
    });
    return {
      ...toRefs(details),
      ...toRefs(methods),
      disinfectType,
      totalImg,
      moment
    };
  },
});

</script>
<style lang="scss" scoped>
.newdiv {
  position: relative;
}

.taskDetail {
  position: relative;
  padding: 24px;
  background: RGBA(240, 242, 245, 1);

  .xsstatus {
    margin-bottom: 10px;
  }

  .title {
    margin-top: 0;

    img {
      position: relative;
      width: 26px;
      top: 4px;
      cursor: pointer;
    }

    span {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .contentsuoyou {
    // position: relative;
    max-width: 1450px;
    display: flex;
    width: 100%;
    height: 622px;
  }

  .contentBox {
    min-height: 100px;
    padding: 20px 0 20px 40px;
    margin-bottom: 20px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 1);
    max-width: 1450px;
  }

  .contenttask {
    margin-bottom: 20px;
    border-radius: 2px;
    margin-left: 20px;
    flex: 1;
    // position: absolute;
    // left: 61.4%;
    // top: 0;
    // right: 0;
    height: 100%;
    background: #fff;
    overflow: auto;
    padding-top: 20px;

    .detilendpointbox {
      margin-left: 58px;
      border-left: 1px solid #acacac;

      // min-width: 530px;
      .fatserson {
        margin-left: -68px;
        height: 41px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgba(0, 0, 1, 0.85);
        position: relative;

        .iconmap4 {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 30px;
        }

        .newminbox {
          width: 55%;
          z-index: 999;
        }

        .fatserson1 {
          height: 26px;
          background: #ffffff;
          margin-left: 62px;
          width: 20px;
          display: flex;
          align-items: center;
          margin-right: 9px;
          z-index: 999;

          .fatserson1son {
            width: 11px;
            height: 11px;
            background: #05B4BC;
            border-radius: 50px;
          }

          .fatserson1sonhuise {
            background: rgba(0, 0, 0, 0.3);
          }
        }

        .fatsersonrwks {
          position: absolute;
          width: 100px;
          height: 10px;
          background: #ffffff;
          bottom: 0;
        }

        .fatserson2 {
          height: 26px;
          background: #ffffff;
          margin-left: 58px;
          width: 20px;
          display: flex;
          align-items: center;
          margin-right: 14px;
          color: #FFFFFF;
          z-index: 999;

          .fatserson2son {
            width: 21px;
            height: 21px;
            background: #05B4BC;
            border-radius: 50px;
            text-align: center;
            line-height: 19px;
            font-size: 16px;
          }

          .fatserson2sonhuise {
            background: rgba(0, 0, 0, 0.3);
          }
        }

        .colorfathercolor {
          background: #DDF0F9;
        }

        .fatserson2none {
          background: none;
        }

        .fatserimagefather1 {
          background: #ffffff;
          margin-left: 58px;
          width: 26px;
          display: flex;
          align-items: center;
          margin-right: 7px;
          height: 40px;

          .fatserimage1 {
            width: 21px;
            height: 21px;
          }
        }

        .textlefts {
          margin-left: 23px;
          font-size: 12px;
          z-index: 999;
          color: rgba(0, 0, 1, 0.65);
        }

        .textright {
          margin-left: 35px;
        }

        .timeclass {
          margin-left: 24px;
          font-size: 18px;
          font-weight: bold;
          color: #FD2445;
          z-index: 9999;
        }
      }
    }

    .colorblack {
      background: #DDF0F9;
      position: absolute;
      width: 93%;
      height: 100%;
      left: 40px;
      border-radius: 8px;
      top: 0;
    }
  }

  .fatserwancheng {
    color: #05B4BC;
  }

  .textleftswancheng {
    margin-left: 23px;
    font-size: 18px;
    font-weight: 500;
  }

  .contentmap {
    width: 692px;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;

    .disfinectType {
      width: 100%;
      height: 100%;
    }

    .noMap {
      position: absolute;
      margin: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .noMapImg {
        width: 76px;
        height: 76px;
        margin-bottom: 10px;
      }
    }
  }

  .taskdisinfect {
    // width: 100%;
  }

  .infoItem {
    z-index: 999;

    p {
      span {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }

      span:nth-child(1) {
        width: 130px;
        padding-right: 20px;
        text-align: left;
        font-weight: bold;
      }

      .ssidname {
        width: 126px;
        word-wrap: break-word;
        word-break: break-all;
        cursor: pointer;
        color: #1890ff;
      }
    }
  }

  .mapBox {
    img {
      display: inline-block;
      // max-height: 80%;
      width: 100%;
    }

    #canvasBox {
      margin-right: 10px;
      width: 100%;
      height: 100%;
      position: relative;

      .hotImg {
        position: absolute;
        right: 0;
        bottom: 5px;
        z-index: 300;
        cursor: pointer;
        width: 54px;
        height: 54px;
      }

      .zoom {
        position: absolute;
        left: 10px;
        bottom: 5px;
        width: 106px;
        height: 54px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 300;
        cursor: pointer;

        .reduce,
        .add {
          width: 50%;
          z-index: 301;
        }

        .zoomImg {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 106px;
          height: 54px;
        }
      }
    }
  }

  .borderclass {
    width: 100%;
    margin-bottom: 20px;
    line-height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

}

.taskInfo {
  ::v-deep(.needGo) {
    span {
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }
  }
}</style>
